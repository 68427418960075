<template>
  <div class="main-body bodybg">
    <div class="box-img">
      <el-image :src="require('@/assets/index/simidingzi.png')"></el-image>
    </div>
    <div class="box-nav">
      <ul>
        <li>{{ cusWord.title }}</li>
      </ul>
    </div>
    <div class="box-from">
      <div class="main-box">
        <div class="box-font">
          <div class="box-font1">告诉我你要去哪里</div>
          <div class="box-font2">精彩就在下一站</div>
        </div>
        <div class="main-from">
          <el-form ref="form" :rules="rules" :model="form">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item prop="destination">
                      <el-input placeholder="目的地" v-model="form.destination">
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-location"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="beg_date">
                      <el-date-picker
                        style="width: 100%"
                        v-model="form.beg_date"
                        align="right"
                        type="date"
                        placeholder="出行日期"
                        :picker-options="pickerOptions"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-time"
                        ></i>
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item prop="travel_days">
                      <el-input
                        placeholder="出行天数"
                        v-model="form.travel_days"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-tickets"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="customer_country_code">
                      <el-select
                        style="width: 100%"
                        v-model="form.customer_country_code"
                        filterable
                        placeholder="国家区号"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-discover"
                        ></i>

                        <el-option
                          v-for="(item, index) in article"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item prop="customer_tel">
                      <el-input
                        placeholder="联系电话"
                        v-model="form.customer_tel"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-phone"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="customer_email">
                      <el-input
                        placeholder="邮箱"
                        v-model="form.customer_email"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-message"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="customer_demo">
                  <el-input
                    placeholder="备注您的特殊要求"
                    :rows="7"
                    v-model="form.customer_demo"
                    type="textarea"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" :offset="16">
                <el-button class="el-but" @click="resetForm('form')"
                  >重置</el-button
                >
                <el-button class="el-but" @click="submitForm('form')"
                  >提交</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="box-foot">
          <el-row :gutter="80">
            <el-col :span="6">
              <div class="foot-left">
                <div class="foot-left-img">
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="rightInfo.e_pv_img"
                  ></el-image>
                </div>
                <div class="foot-left-img-font">码上扫一扫</div>
                <div class="foot-left-js">
                  <div>
                    <span class="s1">直接和</span
                    ><span class="s2">&nbsp;专业顾问&nbsp; </span
                    ><span class="s1">微信沟通需求</span>
                  </div>
                  <div>
                    <span class="s1">来一次</span
                    ><span class="s2">&nbsp;属于自己&nbsp;</span
                    ><span class="s1">的旅行</span>
                  </div>
                </div>
                <div class="box-foot-left-icon">
                  <div class="box-icons">
                    <div class="icons">
                      <div class="ico">
                        <svg-icon icon-class="zixun-icon"></svg-icon>
                      </div>
                    </div>
                    <div class="box-font-1">提交网单咨询</div>
                  </div>
                  <div class="box-icons">
                    <div class="icons">
                      <div class="ico">
                        <svg-icon icon-class="dingzhi-icon"></svg-icon>
                      </div>
                    </div>
                    <div class="box-font-1">一对一定制</div>
                  </div>
                  <div class="box-icons">
                    <div class="icons">
                      <div class="ico">
                        <svg-icon icon-class="fanghu-icon"></svg-icon>
                      </div>
                    </div>
                    <div class="box-font-1">安全出行</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="18">
              <div class="foot-right">
                <router-link
                  :to="{
                    path: '/travel-route/detail',
                    query: { route_bn: list.route_bn },
                  }"
                  class="foot-right-1"
                  v-for="(list, i) in smObj"
                  :key="i"
                >
                  <div class="foot-right-1-up">
                    <el-image fit="cover" :src="list.ad_img"></el-image>
                  </div>
                  <div class="foot-right-1-down">
                    <div class="font-down-1">{{ list.ad_title }}</div>
                    <div class="font-down-2">{{ list.ad_subtitle }}</div>
                  </div>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import article from '@/utils/article';
import {
  cusWord,
  hpBlock,
  routeTagDetailSearch,
  getRightInfo,
} from '@/api/index';

export default {
  name: 'index',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      cusWord: '',
      form: {
        cus_type: '1', //来源类型
        destination: '', //目的地
        beg_date: '', //出行开始日期
        travel_days: '', //出行天数
        customer_country_code: '', //国家区号
        customer_tel: '', //电话
        customer_email: '', //email
        customer_demo: '', //备注
      },
      rules: {
        destination: [
          { required: true, message: '请输入目的地', trigger: 'blur' },
        ],
        beg_date: [
          { required: true, message: '请输入出行日期', trigger: 'blur' },
        ],
        travel_days: [
          { required: true, message: '请选择出行天数', trigger: 'blur' },
        ],
        customer_country_code: [
          { required: true, message: '请选择国家区号', trigger: 'blur' },
        ],
        customer_tel: [
          { required: true, message: '请输入号码', trigger: 'blur' },
        ],
        customer_email: [
          { required: true, message: '请输入email', trigger: 'blur' },
        ],
      },
      smObj: [],
      article: article,
      rightInfo: {},
    };
  },
  methods: {
    getRightInfoFunc() {
      getRightInfo()
        .then((res) => {
          this.rightInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(form) {
      console.log(this.$refs[form]);
      hpBlock(this.$refs[form].model)
        .then((res) => {
          this.$message({
            message: res.message,
            type: 'success',
            duration: 5 * 1000,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 私密定制 - 说明文字
    getCusWord() {
      cusWord()
        .then((res) => {
          this.cusWord = res.data[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 首页-私密定制-广告
    getCusAd() {
      routeTagDetailSearch({ tagid: 'tag_type_001' })
        .then((res) => {
          var data = res.data;
          var fag = true;
          for (let i = 0; i < data.length; i++) {
            fag = this.valImage(data[i].ad_img);
          }
          if (fag) {
            this.smObj = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 判断图片地址是否有效
    valImage(pathImg) {
      let ImgObj = new Image();
      ImgObj.src = pathImg;
      if (ImgObj.fileSize < 0 || (ImgObj.width < 0 && ImgObj.height < 0)) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.getCusWord();
    this.getCusAd();
    this.getRightInfoFunc();
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}

.main-body {
  padding-top: 81px;

  .box-img {
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
  }

  .box-nav {
    padding-bottom: 48px;

    ul {
      display: flex;
      justify-content: center;

      li {
        font-size: 22px;
        color: #711e92;
        /*padding: 0 10px;*/
        /*border-right: 2px solid #711E92;*/
        /*a*/
      }

      .li-none {
        border: none;
      }
    }
  }

  .box-from {
    width: 100%;
    background-image: url('../../../assets/index/quna.png');
    display: flex;
    justify-content: center;

    .main-box {
      width: 1200px;

      .box-font {
        margin-top: 41px;
        color: #ffffff;

        .box-font1 {
          font-size: 22px;
        }

        .box-font2 {
          margin: 5px 0 25px 0;
          font-size: 12px;
        }
      }

      .main-from {
        background: rgba(255, 255, 255, 1);
        opacity: 0.64;
        border-radius: 8px;
        padding: 30px;
        margin-bottom: 30px;

        .el-row {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .el-but {
          background-color: #6f0f95;
          width: 180px;
          color: #ffffff;
          opacity: 1;
        }
      }

      .box-foot {
        .foot-left {
          /*display: flex;*/
          /*justify-content: center;*/
          text-align: center;

          .foot-left-img {
            margin: 0 auto;
            width: 94px;
            height: 94px;
            padding: 6px;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
          }

          .foot-left-img-font {
            font-size: 14px;
            color: rgba(255, 255, 255, 1);
            margin-bottom: 22px;
          }

          .foot-left-js {
            color: #ffffff;
            font-size: 14px;

            .s2 {
              font-size: 18px;
            }
          }

          .box-foot-left-icon {
            display: flex;
            justify-content: center;
            margin-bottom: 45px;
            margin-top: 10px;

            .box-icons {
              display: inline-block;
              color: #ffffff;
              margin: 0 8px;

              .icons {
                display: inline-block;
                font-size: 20px;
                width: 52px;
                height: 52px;
                background: rgba(255, 255, 255, 1);
                border-radius: 50%;

                .ico {
                  transform: translateY(50%);
                }
              }

              .box-font-1 {
                font-size: 12px;
              }
            }
          }
        }

        .foot-right {
          display: flex;
          justify-content: space-between;

          .foot-right-1 {
            position: relative;
          }

          .foot-right-1-up {
            width: 206px;
            height: 137px;

            :deep(.el-image) {
              border-radius: 4px;
              width: 100%;
              height: 100%;
            }
          }

          .foot-right-1-down {
            width: 185px;
            height: 141px;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            position: absolute;
            left: 10.5px;
            top: 125px;
            text-align: center;
            padding: 0 20px;

            .font-down-1 {
              font-size: 18px;
              color: rgba(51, 51, 51, 1);
              line-height: 65px;
            }

            .font-down-2 {
              font-size: 12px;
              color: rgba(153, 153, 153, 1);
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
</style>