<template>
  <div class="bodybg main">
    <div class="main-1">
      <div class="box1">
        <div class="box-1">
          <el-image :src="require('@/assets/index/bjrm.png')"></el-image>
        </div>
      </div>
      <div class="box2-main">
        <div class="box2">
          <div class="box2-1">
            <div
              class="box2-nav"
              v-for="(item, i) in obj"
              :class="i == active ? 'bg' : ''"
              @click="tagClick(i)"
              :key="i"
            >
              {{ item.tag_title }}
            </div>
          </div>
          <div class="box2-2">
            <div class="box2-2-1">
              <div class="box-newObj" v-for="(item, i) in newObj" :key="i">
                <div class="box-title">{{ item.tag_key_nm }}</div>
                <div class="box-sub-title">{{ item.tag_title }}</div>
                <div>
                  <ul>
                    <li class="slh" v-for="(it, t) in subNewObj[i]" :key="t">
                      <router-link
                        :to="{
                          path: '/travel-route/detail',
                          query: { route_bn: it.route_bn },
                        }"
                      >
                        <svg-icon icon-class="fangkuai"></svg-icon>
                        <a href="javascript:void(0)" :title="it.ad_title">{{ it.ad_title }}</a>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="box2-2-2">
              <router-link
                :to="{
                  path: '/travel-route/detail',
                  query: { route_bn: item.route_bn },
                }"
                class="box-list"
                v-for="(item, i) in isActiveObj"
                :key="i"
              >
                <div class="box-img">
                  <el-image :src="item.ad_img"></el-image>
                  <div class="box-city">出发地：{{ item.start_city }}</div>
                </div>
                <div class="box-font">
                  <el-row>
                    <el-col :span="9">
                      <div class="box-font-left">{{ item.ad_title }}</div>
                    </el-col>
                    <el-col :span="9" :offset="6">
                      <div class="box-font-right">{{ item.route_price }}</div>
                    </el-col>
                  </el-row>
                </div>
              </router-link>
              <div
                class="box-list other"
                v-if="originIsActiveObj.length > 5"
                @click="showMore"
              >
                <i class="icon"></i>
                <div class="text">查看更多</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { routeTagSearch, routeTagDetailSearch } from '@/api/index';

export default {
  name: 'index',
  data() {
    return {
      active: 0,
      obj: [],
      subObj: [],
      isActiveObj: [],
      newObj: {},
      subNewObj: [],
      originIsActiveObj: [],
      curObj: {
        region_nm: '',
        region_id: '', // 地区
        country_bn: '', // 国家
        start_city_bn: '', // 开始城市
        end_city_bn: '', // 结束城市
      },
    };
  },
  created() {
    this.getRecommendNew();
    this.getRecommendHot();
  },
  mounted() {},
  methods: {
    ...mapActions(['changSetRouteObj']),
    showMore() {
      this.changSetRouteObj(this.curObj);
      const { href } = this.$router.resolve({ path: '/travel-route/list' });
      window.open(href, '_blank');
    },
    tagClick(i) {
      this.active = i;
      this.curObj.region_nm = this.obj[i].tag_title;
      this.curObj.region_id = this.obj[i].w_region;
      this.curObj.country_bn = this.obj[i].w_country;
      this.curObj.start_city_bn = this.obj[i].w_start_city;
      this.curObj.end_city_bn = this.obj[i].w_end_city;
      this.originIsActiveObj = this.subObj[i];
      this.isActiveObj = this.subObj[i].slice(0, 5);
    },
    // 首页-新点聚集-新灵感
    getRecommendNew() {
      routeTagSearch({ tagid: 'tag_type_005' })
        .then((res) => {
          this.newObj = res.data;
          this.newObj.forEach((item, index) => {
            routeTagDetailSearch({ tagid: item.id })
              .then((res) => {
                this.subNewObj[index] = res.data;
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 首页-本季热卖
    getRecommendHot() {
      routeTagSearch({ tagid: 'tag_type_004' })
        .then((res) => {
          this.obj = res.data;
          this.obj.forEach((item, index) => {
            routeTagDetailSearch({ tagid: item.id })
              .then((res) => {
                this.subObj[index] = res.data || [];
                if (index === 0) {
                  this.tagClick(this.active);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;

  .main-1 {
    .box1 {
      display: flex;
      justify-content: center;

      .box-1 {
        margin: 70px 0 60px 0;
      }
    }

    .box2-main {
      display: flex;
      justify-content: center;

      .box2 {
        width: 1203px;
        padding-bottom: 60px;

        .box2-1 {
          display: flex;
          justify-content: center;
          margin-bottom: 50px;

          .box2-nav {
            padding: 8px 18px;
            cursor: pointer;
          }

          .bg {
            color: #ffffff;
            background: rgba(107, 15, 153, 1);
            box-shadow: 0px 3px 13px 0px rgba(113, 30, 146, 0.16);
            border-radius: 15px;
          }
        }

        .box2-2 {
          display: flex;
          .box2-2-1 {
            display: inline-block;
            width: 330px;
            background: rgba(255, 255, 255, 1);
            padding: 30px;
            .box-title {
              display: none;
              font-size: 24px;
              color: rgba(61, 126, 255, 1);
            }

            .box-sub-title {
              font-size: 16px;
              color: rgba(51, 51, 51, 1);
              margin: 14px 0 0 0;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(61, 126, 255, 1);
              line-height: 26px;
            }

            .box-newObj {
              .title {
                font-size: 18px;
                color: rgba(61, 126, 255, 1);
                margin: 30px 0 10px 0;
              }

              li {
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
                margin: 5px;
              }
            }
          }

          .box2-2-2 {
            width: 900px;
            display: inline-block;
            .marg {
              margin-bottom: 20px;
            }
            .box-list {
              &.other {
                background: rgba(107, 15, 153, 0.7);
                border-radius: 4px;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .icon {
                  width: 50px;
                  height: 50px;
                  display: inline-block;
                  background: url("../../../assets/index/icon-046.png")
                    no-repeat;
                  background-size: 100% 100%;
                  margin-bottom: 15px;
                }
                .text {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: rgba(255, 255, 255, 1);
                  line-height: 16px;
                }
              }
              cursor: pointer;
              width: 271px;
              height: 343px;
              display: inline-block;
              vertical-align: middle;
              margin-left: 20px;
              background: rgba(255, 255, 255, 1);
              box-shadow: 0px 3px 13px 0px rgba(113, 30, 146, 0.16);
              border-radius: 4px;
              margin-bottom: 20px;
              &:nth-child(n + 4) {
                margin-bottom: 0;
              }
              .box-img {
                width: 270px;
                height: 270px;
                position: relative;
                :deep(.el-image) {
                  width: 100%;
                  height: 100%;
                }
                .box-city {
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  background: rgba(0, 0, 0, 0.58);
                  font-size: 12px;
                  color: rgba(255, 255, 255, 1);
                  padding: 10px;
                }
              }

              .box-font {
                width: 270px;
                background-color: #ffffff;
                padding: 15px;
                .box-font-left {
                  font-size: 12px;
                  color: rgba(107, 15, 153, 1);
                }
                .box-font-right {
                  font-size: 24px;
                  color: rgba(201, 29, 50, 1);
                }
              }
            }
          }
        }
        .box-newObj {
          &:first-child {
            .box-title {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>