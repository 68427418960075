<template>
  <div class="main-box">
    <div class="bodybg box-1">
      <div>
        <el-image :src="require('@/assets/index/mddct.png')"></el-image>
      </div>
    </div>
    <div class="box-2">
      <div class="box-2-1">
        <el-row>
          <el-col :span="6">
            <div class="box-2-1-left">
              <div class="box-2-1-left-font">
                搜索团期
                <i slot="prefix" class="el-icon-search"></i>
              </div>
              <el-form ref="form" :rules="rules" :model="form">
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item prop="date_beg">
                      <el-date-picker
                        style="width: 100%"
                        v-model="form.date_beg"
                        align="right"
                        type="date"
                        placeholder="出行日期"
                        :picker-options="pickerOptions"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-time"
                        ></i>
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item prop="date_end">
                      <el-date-picker
                        style="width: 100%"
                        v-model="form.date_end"
                        align="right"
                        type="date"
                        placeholder="返回日期"
                        :picker-options="pickerOptions"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-time"
                        ></i>
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item prop="where_want">
                      <el-input placeholder="目的地" v-model="form.where_want">
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-location"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-form-item prop="key_word">
                      <el-input
                        placeholder="请输入关键字"
                        v-model="form.key_word"
                      >
                        <i
                          slot="prefix"
                          class="el-input__icon el-icon-phone"
                        ></i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-button class="el-but" @click="submitForm('form')"
                      >提交</el-button
                    >
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-col>
          <el-col :span="18">
            <div class="box-2-1-right">
              <el-row :gutter="38">
                <el-col :span="6" v-for="(item, i) in desObj" :key="i">
                  <div
                    v-if="i < 4"
                    class="right-box-1"
                    @click="handleClick(item, i)"
                  >
                    <el-image
                      class="image1"
                      fit="cover"
                      :src="destinationInfo[`destination_0${i + 1}_f`]"
                    ></el-image>
                    <el-image
                      class="image2"
                      fit="cover"
                      :src="destinationInfo[`destination_0${i + 1}_b`]"
                    ></el-image>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  routeTagDetailSearch,
  searchHp,
  getDestinationInfo,
} from '@/api/index';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'index',
  data() {
    return {
      desObj: [],
      form: {
        page: 1,
        per_page: 10,
        date_beg: '', //来源类型
        date_end: '', //目的地
        where_want: '', //出行开始日期
        key_word: '', //出行天数
        currency: '1',
      },
      rules: {
        // where_want: [
        //   { required: true, message: "请输入目的地", trigger: "blur" }
        // ],
        // key_word: [{ required: true, message: "请输入关键字", trigger: "blur" }]
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            },
          },
          {
            text: '明天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: '一周后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      searchHpObj: {},
      destinationInfo: {},
    };
  },
  computed: {
    ...mapState(['home']),
  },
  created() {
    this.getDestinationInfoFunc();
  },
  methods: {
    ...mapActions(['changSetRouteObj']),
    // 首页-目的地参团
    getDestinationInfoFunc() {
      getDestinationInfo()
        .then((res) => {
          console.log(res);
          this.desObj = res.ad_list;
          this.destinationInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(form) {
      this.form.currency = this.home.currency.currency_id;
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log('confirm', this[form]);
          searchHp(this[form]).then((response) => {
            // 1 线路详情 2 线路列表
            if (response.select_type === '2') {
              this.changSetRouteObj({
                start_date: response.w_beg_date,
                end_date: response.w_end_date,
                region_nm: '',
                region_id: response.w_region, // 地区
                country_bn: response.w_country, // 国家
                start_city_bn: response.w_start_city, // 开始城市
                end_city_bn: response.w_end_city, // 结束城市
              });
              const { href } = this.$router.resolve({
                path: '/travel-route/list',
              });
              window.open(href, '_blank');
            } else if (response.select_type === '1') {
              this.$router.push({
                path: '/travel-route/detail',
                query: {
                  route_bn: response.route_bn,
                },
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    handleClick(item) {
      this.$router.push({
        path: '/travel-route/detail',
        query: { route_bn: item.route_bn },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  width: 100%;

  .box-1 {
    padding-top: 62px;
    display: flex;
    justify-content: center;
    padding-bottom: 36px;
  }

  .box-2 {
    background: url("../../../assets/index/mddct-bag.png") no-repeat;
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    
    .box-2-1 {
      margin-top: 90px;
      width: 1200px;

      .box-2-1-left {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 3px 13px 0px rgba(113, 30, 146, 0.16);
        border-radius: 4px;
        padding: 40px 54px 30px 44px;

        .box-2-1-left-font {
          text-align: center;
          color: #e48035;
          font-size: 24px;
          margin-bottom: 20px;
        }

        .el-form-item {
          margin-bottom: 15px;
        }

        .el-input__inner {
          height: 30px !important;
        }

        .el-but {
          width: 100%;
          background: rgba(106, 18, 140, 1);
          border-radius: 4px;
          color: #ffffff;
        }
      }

      .box-2-1-right {
        margin-top: 100px;
        margin-left: 50px;

        .right-box-1 {
          display: inline-block;
          width: 182px;
          height: 183px;
          border-radius: 50%;
          position: relative;

          .image1 {
            position: absolute;
            top: 0;
            left: 0;
            backface-visibility: hidden;
            transition: 1s;
          }

          .image2 {
            position: absolute;
            top: 0;
            left: 0;
            transform: rotateY(-180deg);
            backface-visibility: hidden;
            transition: 1s;
          }

          &:hover .image1 {
            transform: rotateY(180deg);
          }
          &:hover .image2 {
            transform: rotateY(0deg);
          }

          :deep(.el-image) {
            width: 100%;
            height: 100%;
          }
          :deep(.el-image__inner),
          :deep(.el-image__placeholder),
          :deep(.el-image__error) {
            border-radius: 50%;
          }
          .right-box-1-main {
            .right-box-1-1 {
              position: absolute;
              left: calc(50% - 50px);
              bottom: 22px;
              color: #ffffff;
              text-align: center;

              div:nth-child(1) {
                font-size: 19px;
                margin-bottom: 5px;
              }

              div:nth-child(2) {
                font-size: 13px;
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}
</style>