<template>
  <div class="main-mp4" :style="{'width': getClientWidth,'height':getClientHeight}">
    <video id="movie" class="l-background" :src="src"
           autoplay
           muted
           loop
    >
    </video>
  </div>
</template>
<script>
  export default {
    name: "index",
    data() {
      return {
        src: require('@/assets/video/aiguemarine_sans_logo_1.mp4'),
        rate: 650/1920,
        scollWidth: 17
      };
    },
    computed: {
      fag() {
        return this.$store.state.home.fag;
      },
      getClientWidth() {
        console.log(document.documentElement.clientWidth)
        let clientWidth = (document.documentElement.clientWidth - this.scollWidth) + "px"
        return clientWidth
      },
      getClientHeight() {
        let clientHeight = (document.documentElement.clientWidth - this.scollWidth) * this.rate + "px"
        return clientHeight;
      }
    },
    watch: {
      // fag: 'toggleSound'
    },
    methods: {
      toggleSound() {
        var video = document.getElementById('movie');
        if (video.paused) { //如果已暂停则播放
          video.play(); //播放控制
        } else { // 已播放点击则暂停
          video.pause(); //暂停控制
        }
      },
    }
  };
</script>
<style lang="scss" scoped>
  .main-mp4 {
    //width: var(--screenWidth);
    // height: 650px;

    .l-background {
      width: 100%;
      height: 100%;
      object-fit: fill
    }
  }

  /*}*/
</style>


