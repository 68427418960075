<template>
  <div>
    <div class="bodybg">
      <div class="box-img">
        <el-image :src="require('@/assets/index/smdz-index.png')"></el-image>
      </div>
      <div class="box-swi">
        <div class="box-man">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide class="swiper-slide" v-for="(item,index) in cusObj" :key="index">
              <router-link :to="{ path: '/travel-route/detail', query: {route_bn: item.route_bn}}">
                <div class="swiper-slide-box-1">{{item.ad_title}}</div>
                <div class="swiper-slide-box-2">{{item.ad_subtitle}}</div>
                <div class="swiper-slide-box-3-pos">
                  <el-image :src="item.ad_img"></el-image>
                  <div class="swiper-slide-box-3">{{item.ad_word}}</div>
                </div>
                <div class="swiper-slide-box-4">
                  <div class="swiper-slide-box-4-1">{{item.disp_sn}}</div>
                  <div class="swiper-slide-box-4-2"><span style="color: #999999">起价/人</span>{{item.route_price}}</div>
                </div>
                <div class="swiper-slide-box-5" :title="item.route_title">{{item.route_title}}</div>
              </router-link>
            </swiper-slide>
          </swiper>
          <!-- 左右箭头 -->
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { routeTagDetailSearch } from '@/api/index'

export default {
  name: 'index',
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 13,
        clickable: true,
        //显示分页
        pagination: {
          el: '.swiper-pagination'
        },
        //设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        //自动轮播
        autoplay: {
          delay: 2000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false
        }
        //开启循环模式
      },
      cusObj: [
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        },
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        },
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        },
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        },
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        },
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        },
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        },
        {
          id: 'recommend_cus_10',
          disp_sn: '10',
          title: '意大利希腊阳光海岸行',
          subtitle: '意大利·希腊',
          slogan: '浪漫之族',
          img: require('@/assets/index/1.jpg'),
          dec:
            '携手心爱的人，漫步于罗马城中，重温历史；亦或是享受爱情海的碧海蓝天，一起情迷于蓝白结合的小岛上。',
          price: '18888元',
          days: '15天13晚',
          url: './views/travel_route'
        }
      ]
    }
  },
  created() {
    this.getRecommendCus()
  },
  mounted() {
    console.log(this.$refs.mySwiper.$swiper)
    this.$refs.mySwiper.$swiper.el.onmouseover = () => { //鼠标放上暂停轮播
      this.$refs.mySwiper.$swiper.autoplay.stop();
    }
    this.$refs.mySwiper.$swiper.el.onmouseleave = () => {
      this.$refs.mySwiper.$swiper.autoplay.start();
    }
  },
  methods: {
    // 首页-定制推荐
    getRecommendCus() {
      routeTagDetailSearch({ tagid: 'tag_type_002' })
        .then(res => {
          var data = res.data
          var fag = true
          for (let i = 0; i < data.length; i++) {
            fag = this.valImage(data[i].ad_img)
          }
          if (fag) {
            this.cusObj = data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 判断图片地址是否有效
    valImage(pathImg) {
      let ImgObj = new Image()
      ImgObj.src = pathImg
      if (ImgObj.fileSize < 0 || (ImgObj.width < 0 && ImgObj.height < 0)) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.bodybg {
  padding-top: 81px;
  padding-bottom: 10px;
}

.box-img {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.box-swi {
  width: 1064px;
  margin: 0 auto;
  text-align: center;

  .box-man {
    position: relative;

    .swiper-button-prev {
      left: -40px;
    }

    .swiper-button-next {
      right: -40px;
    }

    .swiper-container {
      .swiper-slide {
        z-index: 10;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 10px 0px rgba(113, 30, 146, 0.16);
        border-radius: 3px;

        .swiper-slide-box-1 {
          font-size: 20px;
          color: rgba(51, 51, 51, 1);
          margin: 15px 0;
        }

        .swiper-slide-box-2 {
          font-size: 16px;
          color: rgba(153, 153, 153, 1);
          margin-bottom: 15px;
        }

        .swiper-slide-box-3-pos {
          position: relative;
          height: 194px;
          .swiper-slide-box-3 {
            background: rgba(255, 255, 255, 1);
            opacity: 0.64;
            position: absolute;
            top: 0;
            left: 0;
            color: rgba(51, 51, 51, 1);
            z-index: 1;
            padding: 5px 10px;
          }
        }

        .swiper-slide-box-4 {
          display: flex;
          justify-content: space-between;
          padding: 0 8px;

          .swiper-slide-box-4-1 {
            font-size: 18px;
            color: rgba(51, 51, 51, 1);
          }

          .swiper-slide-box-4-2 {
            color: #6f0f95;
            font-size: 16px;
          }
        }

        .swiper-slide-box-5 {
          font-size: 14px;
          color: rgba(102, 102, 102, 1);
          padding: 5px;
          margin-bottom: 5px;
          height: 113px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          text-align: left;
        }
      }
    }
  }
}
</style>
