<template>
  <div>
    <video-mp4></video-mp4>
    <si-mi-ding-zhi></si-mi-ding-zhi>
    <ding-zhi-tui-jian></ding-zhi-tui-jian>
    <mu-di-di-c-t></mu-di-di-c-t>
    <hot-tui-jian></hot-tui-jian>
  </div>
</template>

<script>
import VideoMp4 from './components/VideoMp4'
import SiMiDingZhi from './components/SiMiDingZhi'
import MuDiDiCT from './components/MuDiDiCT'
import DingZhiTuiJian from './components/DingZhiTuiJian'
import HotTuiJian from './components/HotTuiJian'
import { mapActions } from 'vuex'
export default {
  name: 'index',
  components: {
    VideoMp4,
    MuDiDiCT,
    DingZhiTuiJian,
    HotTuiJian,
    SiMiDingZhi,
  },
  data() {
    return {}
  },
  created() {
    this.changSetRouteObj({})
  },
  methods: {
    ...mapActions(['changSetRouteObj']),
  }
}
</script>
<style lang="scss">
body {
  overflow-x:hidden
}
</style>
