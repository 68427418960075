var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bodybg main"},[_c('div',{staticClass:"main-1"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"box-1"},[_c('el-image',{attrs:{"src":require('@/assets/index/bjrm.png')}})],1)]),_c('div',{staticClass:"box2-main"},[_c('div',{staticClass:"box2"},[_c('div',{staticClass:"box2-1"},_vm._l((_vm.obj),function(item,i){return _c('div',{key:i,staticClass:"box2-nav",class:i == _vm.active ? 'bg' : '',on:{"click":function($event){return _vm.tagClick(i)}}},[_vm._v(" "+_vm._s(item.tag_title)+" ")])}),0),_c('div',{staticClass:"box2-2"},[_c('div',{staticClass:"box2-2-1"},_vm._l((_vm.newObj),function(item,i){return _c('div',{key:i,staticClass:"box-newObj"},[_c('div',{staticClass:"box-title"},[_vm._v(_vm._s(item.tag_key_nm))]),_c('div',{staticClass:"box-sub-title"},[_vm._v(_vm._s(item.tag_title))]),_c('div',[_c('ul',_vm._l((_vm.subNewObj[i]),function(it,t){return _c('li',{key:t,staticClass:"slh"},[_c('router-link',{attrs:{"to":{
                        path: '/travel-route/detail',
                        query: { route_bn: it.route_bn },
                      }}},[_c('svg-icon',{attrs:{"icon-class":"fangkuai"}}),_c('a',{attrs:{"href":"javascript:void(0)","title":it.ad_title}},[_vm._v(_vm._s(it.ad_title))])],1)],1)}),0)])])}),0),_c('div',{staticClass:"box2-2-2"},[_vm._l((_vm.isActiveObj),function(item,i){return _c('router-link',{key:i,staticClass:"box-list",attrs:{"to":{
                path: '/travel-route/detail',
                query: { route_bn: item.route_bn },
              }}},[_c('div',{staticClass:"box-img"},[_c('el-image',{attrs:{"src":item.ad_img}}),_c('div',{staticClass:"box-city"},[_vm._v("出发地："+_vm._s(item.start_city))])],1),_c('div',{staticClass:"box-font"},[_c('el-row',[_c('el-col',{attrs:{"span":9}},[_c('div',{staticClass:"box-font-left"},[_vm._v(_vm._s(item.ad_title))])]),_c('el-col',{attrs:{"span":9,"offset":6}},[_c('div',{staticClass:"box-font-right"},[_vm._v(_vm._s(item.route_price))])])],1)],1)])}),(_vm.originIsActiveObj.length > 5)?_c('div',{staticClass:"box-list other",on:{"click":_vm.showMore}},[_c('i',{staticClass:"icon"}),_c('div',{staticClass:"text"},[_vm._v("查看更多")])]):_vm._e()],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }